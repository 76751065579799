<template>
	<div :class="`hb-note__body d-flex flex-column rounded-lg text-body ${getNoteClasses(data.type)} px-3 py-2`">
		<div class="d-flex justify-space-between">
			<small class="font-weight-bold text-small text-uppercase">{{ getNoteTypeLabel(data.type) }}</small>
			<div v-if="$slots.info" class="pl-2">
				<slot name="info" />
			</div>
		</div>
		<div>
			<slot />
		</div>
		<div v-if="$slots.footer">
			<slot name="footer" />
		</div>
	</div>
</template>
<script>
import HasNotes from '@/mixins/HasNotes'

export default {
	name: 'NoteBox',

	mixins: [ HasNotes ],

	props: {
		data: {
			type: Object,
			required: true
		}
	}
}
</script>
<style>
	.hb-note__body {
		font-size: 0.925rem;
	}
</style>
