import NoteBox from '@/components/notes/NoteBox'

export default {
	components: {
		NoteBox
	},

	methods: {
		getNoteClasses (type, section = null) {
			switch (type) {
				case 'TYPE_WORKSHOP_NOTE':
					return (section === 'background' ? 'amber lighten-4' : (section === 'text' ? 'orange--text text--darken-4' : 'amber orange--text lighten-4 text--darken-4'))
				case 'TYPE_CUSTOMER_SERVICE_NOTE':
					return (section === 'background' ? 'red lighten-4' : (section === 'text' ? 'red--text text--darken-4' : 'red red--text lighten-4 text--darken-4'))
				case 'TYPE_DRIVER_NOTE':
					return (section === 'background' ? 'light-green lighten-4' : (section === 'text' ? 'light-green--text text--darken-4' : 'light-green green--text lighten-4 text--darken-4'))
				case 'TYPE_FINANCE_NOTE':
					return (section === 'background' ? 'purple lighten-4' : (section === 'text' ? 'purple--text text--darken-4' : 'purple purple--text lighten-4 text--darken-4'))
				case 'TYPE_CUSTOMER_NOTE':
					return (section === 'background' ? 'blue lighten-4' : (section === 'text' ? 'blue--text text--darken-4' : 'blue blue--text lighten-4 text--darken-4'))
			}
			return ''
		},

		getNoteTypeLabel (type) {
			switch (type) {
				case 'TYPE_WORKSHOP_NOTE':
					return 'Workshop Note'
				case 'TYPE_CUSTOMER_SERVICE_NOTE':
					return 'Customer Service Note'
				case 'TYPE_DRIVER_NOTE':
					return 'Driver Note'
				case 'TYPE_FINANCE_NOTE':
					return 'Finance Note'
				case 'TYPE_CUSTOMER_NOTE':
					return 'Customer Note'
			}
			return 'Note'
		}
	}
}
